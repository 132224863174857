<template>
  <v-theme-provider>
    <base-section
      id="boards"
      space="40"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <base-section-heading title="Dewan Direksi" />
            <base-body space="0">
               <v-container fluid>
                <v-row dense>
                  <v-col
                    v-for="card in cards"
                    :key="card.name"
                    md="4"
                    xs="12"
                  >
                    <div 
                    data-aos="zoom-out"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="true"
                    data-aos-anchor-placement="top-center">
                    <v-hover v-slot="{ hover }">
                    <v-card
                    :elevation="hover ? 20 : 0"
                    :class="{ 'on-hover': hover }"
                    outlined>
                      <v-img
                        :src="require(`@/assets/${card.src}`)"
                        class="white--text align-end"
                        height="320px"
                        :gradient="hover ? '' : 'to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)'"
                        :alt="card.name"
                      >
                        <v-card-title class="body-1" v-text="card.name"></v-card-title>
                         <v-card-text v-if="hover" class="caption">
                        {{ card.detail }}
                      </v-card-text>
                      </v-img>
                      <v-card-actions>
                        {{ card.title }}
                      </v-card-actions>
                    </v-card>
                  </v-hover>
                    </div>
                  </v-col>
                  <v-col class="text-center mt-3">
                  <base-btn
                    :block="$vuetify.breakpoint.smAndDown"
                    color="accent"
                    large
                    outlined
                    to="struktur-perusahaan"
                    v-if="$route.name === 'tentang-kami'"
                    class = "text-capitalize"
                  >
                    Struktur Perusahaan
                  </base-btn>
                </v-col>
                </v-row>
              </v-container>
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionBoards',
    data: () => ({
            posts: [],
            cards: [
              { name: 'Didik Marhaendra A. H., ST, MM, CIIB', title: 'Direktur Utama', src: 'didik-marhaendra-direktur-utama.jpg', detail: '' },
              { name: 'Musmualim (Alan)', title: 'Wakil Direktur Utama', src: 'mualan-wakil-direktur.jpeg', detail: '' },
              { name: 'Susanto, SE, MM, CIIB', title: 'Direktur & Tenaga Ahli', src: 'susanto-direktur-tenaga-ahli.jpg', detail: '' },
            ],
        }),
  }
</script>
<style>